import React from "react"
import { Container } from "react-bootstrap"
import { ApButton } from "../../Global/Common/ApButton/ApButton"
import { DownloadSectionStyles } from "./DownloadSection.styles"

const DownloadSection = () => {
  return (
    <DownloadSectionStyles className="py-5 px-4 px-lg-2 secondary-blue-3-background ">
      <Container className="terms-and-condition">
        <h1 className="mb-4">SERVICES DESCRIPTION</h1>
        <h5 className=" text-white">
          Last updated: 12 January 2021
        </h5>
      </Container>
    </DownloadSectionStyles>
  )
}

export default DownloadSection
