import React from "react"
import { Container } from "react-bootstrap"
import { ProductsAndServicesSection } from "./ProductsAndServices.styles"
import { LINKS } from "../../../data/pageLinks.data"
import { Link } from "gatsby"

const CompleteTerms = () => {
  return (
    <ProductsAndServicesSection className="py-5 px-4 px-lg-0">
      <Container>
        <section id="intro">
            <p>
                This page sets out the services that may be made available to APEXX merchants. The descriptions should be read
                together with your services agreement with APEXX (<strong>Agreement</strong>). If you do not yet have an Agreement
                with APEXX or would like further information about any of our Services, please submit a request&nbsp;
                <Link
                  className="secondary-blue-2-text"
                  to={LINKS.contactUs}
                >
                  here
                </Link>.
            </p>
            <p>
                APEXX may update this page from time to time to reflect updates to the Services, including developments or newly
                added services. Please check this page on occasion to be updated on any changes.
            </p>
        </section>
        <section id="paymentTechnologyServices">
            <h2 className="my-5 text-uppercase secondary-blue-2-text">1. PAYMENT TECHNOLOGY SERVICES</h2>
            <h3 className="h4 my-4">1.1 E-commerce Transaction Processing</h3>
            <p>
                The APEXX proprietary payment gateway (<strong>APEXX Gateway</strong>) enables the technical processing of
                card-not-present payment transactions between the cardholder and merchant through the relevant third-party payment
                processors (<strong>Payment Service Provider</strong>). This includes the following payment transactions:
                verification, authorisation, acceptance, decline, cancellation or refund of payment for card-not-present
                transactions.
            </p>
            <p>
                APEXX may be able to offer the merchant the option of requesting pre-authorisation of card-not-present payment
                transactions as supported by, and subject to the applicable terms of, the Payment Service Provider (including set
                capture periods). The functionality of pre-authorisation is set out in the relevant APEXX documentation provided by
                APEXX (<strong>Documentation</strong>). The merchant shall be solely responsible for its use of the
                pre-authorisation functionality and its perception of the applicable capture period. For any card-not-present
                payment transactions captured beyond the capture period, there is a risk that the payment transaction shall
                eventually not be settled, despite the pre-authorisation.
            </p>
            <h3 className="h4 my-4">1.2 3-D Secure (3DS)</h3>
            <p>
                3DS is a protocol designed to be an additional security layer for card-not-present payment transactions. Where
                supported by the Payment Service Provider, APEXX may be able to offer 3DS authentication as an option for
                card-not-present payment transactions processed through the APEXX Hosted Payment Page.
            </p>
            <h3 className="h4 my-4">1.3 Buy Now, Pay Later (BNPL) and Alternative Payment Methods (APM)</h3>
            <p>
                The technical processing via the APEXX Gateway of payment transactions between the merchant customer and merchant
                through a BNPL or AMP operated by a third-party BNPL or AMP provider.
            </p>
            <h3 className="h4 my-4">1.4 Point-of-Sale (POS) Transaction Processing</h3>
            <p>
                The APEXX Gateway enables the technical processing of POS payment transactions between the cardholder and merchant
                submitted through the merchant’s POS device on the merchant site and processed through the relevant Payment Service
                Provider.
            </p>
            <h3 className="h4 my-4">1.5 Pay by Link</h3>
            <p>
                Pay by Link provides a straightforward and secure way for merchants to be paid by their customers through a simple
                payment link which is sent to the customer via email.
            </p>
            <p>
                Merchants are able to customise the email to suit their business needs including branding with their logos. Pay by
                Link is generated via the APEXX Portal (see further information below). By clicking on the payment link, cardholders
                will be redirected to APEXX Hosted Payment Page where card payment details can be captured against a predefined
                amount. This simplifies the payment process for cardholders with very little development and integration work for
                the merchant.
            </p>
            <h3 className="h4 my-4">1.6 Virtual Terminal</h3>
            <p>
                Virtual terminal provides a secure way for merchants to be paid by their customers over the phone by logging into the
                APEXX Portal (see further information below) and entering card payment details for processing by the relevant
                Payment Service Provider.
            </p>
            <h3 className="h4 my-4">1.7 RESTful API (API) &amp; Hosted Payment Page</h3>
            <p>
                The payment details which need to be submitted to enable processing of a card payment transaction can be entered on
                the merchant payment page or through APEXX’s Hosted Payment Page. If the merchant uses the APEXX Hosted Payment
                Page, the cardholder is redirected from the merchant shopping site to the APEXX Hosted Payment Page, on which the
                payment details are entered by the cardholder. The payment details are submitted to the APEXX environment via a
                secure API to be provided by APEXX.
            </p>
            <p>
                A merchant’s use of the API and Hosted Payment Page must at all times be strictly in accordance with the APEXX
                Documentation.
            </p>
            <p>
                The API and Hosted Payment Page can be utilised on the merchant website or as part of its mobile application. The API
                and the Hosted Payment Page may not be used for any purpose, function or feature not described in the APEXX
                Documentation or otherwise communicated by APEXX in writing.
            </p>
            <p>
                APEXX will update the API and Hosted Payment Page Documentation from time to time and reserves the right to add or
                remove functionality. APEXX will provide as much prior written notice as possible if it significantly changes or
                removes any functionality from the API or Hosted Payment Page.
            </p>
            <p>
                APEXX will give the merchant API keys for live and test payment transactions. The merchant shall be solely and wholly
                responsible for the security of those keys and must not share them with any unauthorised persons.
            </p>
            <h3 className="h4 my-4">1.8 APEXX Portal</h3>
            <p>
                The APEXX Portal is a secure, feature-rich, web-based interface that enables merchants to manage their account and
                transactions and access dashboards and reports on transaction activity.
            </p>
            <p>
                The APEXX Portal is provided in English as a default.
            </p>
            <h3 className="h4 my-4">1.9 Operational and Technical Support Services</h3>
            <p>
                APEXX may provide technical support services and dedicated relationship management for a fixed monthly fee. Technical
                support services are available 24/7 via a web-based platform. Relationship management and implementation support
                communications are available during normal business hours.
            </p>
        </section>
        <section id="additionalProducts&Services">
        <h2 className="my-5 text-uppercase secondary-blue-2-text">2. ADDITIONAL PRODUCTS &amp; SERVICES</h2>
            <p>
                The design and functions of the additional services and the way in which a merchant can access them are provided on
                an “as is and as available” basis.
            </p>
            <h3 className="h4 my-4">2.1 Payment Routing</h3>
            <p>
                Payment Routing allows the intelligent routing of transactional traffic to the most beneficial Payment Service
                Provider. It entails the technical routing of payment transactions based on key criteria (currency, transaction
                type, card type, region, time of day etc) to any number of Payment Service Providers in order to increase
                transaction acceptance and reduce the cost of payment processing fees (<strong>Payment Optimisation</strong>).
            </p>
            <p>
                In scenarios where the Payment Service Provider is unable to authorise a transaction, Payment Routing means the
                payment request can be reprocessed and sent on to an alternative Payment Service Provider through the APEXX Gateway.
            </p>
            <p>
                Payment Routing can only be configured where a merchant is using more than one Payment Service Provider connected to
                the APEXX Gateway and the merchant is authorised by the selected Payment Service Providers to process the payment
                transactions being instructed by the Payment Routing engine. &nbsp;
            </p>
            <p>
                For any payment transaction that goes through Payment Routing there is a risk that the payment transaction will not
                achieve Payment Optimisation.
            </p>
            <h3 className="h4 my-4">2.2 Analytics</h3>
            <p>
                Every transaction that passes through the APEXX Gateway represents a data rich opportunity for a merchant to better
                understand their customers, their markets, their business and the performance of their payments ecosystem across
                multiple Payment Service Providers. The aim of APEXX Analytics is to give a merchant the ability to aggregate,
                order, and understand the data that is collected and retained through the APEXX Gateway.
            </p>
            <p>
                The APEXX Analytics dashboard aggregates essential information such as volume, transaction statuses and transaction
                by currency, amongst other metrics, across multiple providers all of which can then be filtered and produced in
                single, comprehensive and customisable reports with granular data mapping. This allows the merchant to properly
                understand the performance of their multiple Payment Service Providers in order to carefully tailor their payments
                ecosystem to boost performance.
            </p>
            <h3 className="h4 my-4">2.3 Fraud Control</h3>
            <p>
                The Payment Service Provider usually allocates the responsibility and risk relating to chargebacks and fraud to the
                merchant. APEXX can support the use of fraud control solutions provided by third parties by enabling payment
                transactions processed through the APEXX Gateway to be screened through such third-party solutions (<strong>Fraud
                Tools</strong>).
            </p>
            <p>
                Depending on the merchant’s use of such Fraud Tools, and the rules a merchant sets in the Fraud Tool, a number of
                checks are performed flagging payment transactions with a likelihood of fraud. It will be the merchant’s sole
                responsibility to accept or reject a flagged payment transaction.
            </p>
            <p>
                The management of the Fraud Tools will be the sole responsibility of the merchant. The merchant shall configure and
                monitor the settings and the parameters (known to indicate risks) available as part of the Fraud Tools, determining
                how they will evaluate payment transactions.
            </p>
            <p>
                Where a merchant utilises Fraud Tools, APEXX explicitly does not guarantee and will have no liability with regard to
                whether any payment transactions which are declined are indeed fraudulent, nor whether any accepted payment
                transactions are not subject to a chargeback or fraud.
            </p>
            <p>
                APEXX at all times reserves the right to cancel payment transactions that it has reasonable grounds to suspect are
                fraudulent or involve other criminal activities, even if a Fraud Tool has failed to block the payment transaction.
            </p>
            <h3 className="h4 my-4">2.4 Bank Identification Number (BIN) Lookup Service</h3>
            <p>
                The BIN lookup service provides real time information about a card payment account based on the first 6 digits of the
                primary account number (PAN). This data can be used by the merchant to make a decision as to how to process the
                transaction (for example, if the customer should proceed through the 3DS process or if the transaction should be
                processed by a particular acquirer).
            </p>
            <p>
                Based on the BIN lookup, the following will be indicated:
            </p>
            <ol type="a">
                <li>Card type (charge, credit, debit, prepaid);</li>
                <li>Who issued the card;</li>
                <li>The country the card was issued in;</li>
            </ol>
            <p>
                APEXX is not liable for any further decision making of the merchant based on their use of the BIN lookup service.
            </p>
            <h3 className="h4 my-4">2.5 Tokenisation</h3>
            <p>
                Tokenisation enables a merchant to take subscription, recurring or one click payments without having to store the
                card credentials (such as card numbers, expiry month, expiry year). Rather, those credentials will be stored by the
                merchant in the form of a token.
            </p>
            <p>
                Tokens can be generated either as a standalone process or as part of the payment process. The token is returned to
                the merchant after a payment or in response to the request to create the token. Alongside the token, the merchant is
                sent the card type, masked card number, expiry month and expiry year.
            </p>
            <p>
                In order to process future payments, the token is sent to APEXX where APEXX de tokenises it to obtain the card data
                stored securely in the APEXX secure cardholder environment and sends it on to the relevant Payment Service Provider
                for processing.
            </p>
        </section>
        <section id="otherServices">
            <h2 className="my-5 text-uppercase secondary-blue-2-text">3. OTHER SERVICES</h2>
            <p>
                APEXX may agree to provide specific services to meet merchant requirements. For example- integration support for a
                Payment Service Provider required by the merchant that is not already integrated to the APEXX Gateway or consultancy
                and project management services including an audit of the merchant’s current payment processing ecosystem to note
                where efficiencies can be created. Please <Link className="secondary-blue-2-text" to={LINKS.contactUs}>contact us</Link> to see how we can support you.&nbsp;
            </p>
        </section>
      </Container>
    </ProductsAndServicesSection>
  )
}

export default CompleteTerms
