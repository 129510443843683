import React from "react"
import Layout from "../components/Global/Layout/Layout"
import DownloadSection from "../components/ProductsAndServices/DownloadSection/DownloadSection"
import ProductsAndServicesContent from "../components/ProductsAndServices/MainContent/ProductsAndServicesContent"
import SEO from "../components/Global/SEO/seo"

const ProductsAndServices = () => (
  <Layout>
    <SEO page="products-and-services" />
    <DownloadSection/>
    <ProductsAndServicesContent/>
  </Layout>
)

export default ProductsAndServices
